import { ENV_DEVELOPMENT } from 'src/app/core/environment.utils';

export const BUILD_URLs = {
  processController: 'https://process-controller.mosaic-dev.siemens-energy.cloud/gen-dige-fe-process-controller.js',
  fileInventory: 'https://file-inventory.mosaic-dev.siemens-energy.cloud/gen-dige-fe-file-inventory.js',
  MANIFEST: '/assets/federation-dev.manifest.json',
};
// NOTE: import environment config from common utils(refer to process controller micro app)

export const environment = {
  production: false,
  dev: true,
  local: false,
  ...ENV_DEVELOPMENT,
};
