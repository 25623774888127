export interface IEnvironmentConfig {
  BASE_URL: string;
  MSAL_CONFIG: IMsalConfig;
  SERVICE_ENDPOINTS: IMicroServices;
}

export interface IMicroServices {
  fileInventory: string,
  processController: string,
  dataExtraction: string,
  issueTrackingSystem: string
}
export interface IMsalConfig {
  clientId: string;
  authorityId: string;
}

export const ENV_COMMON = {
  SERVICE_ENDPOINTS: {
    fileInventory: 'dige-file-inventory',
    processController: 'dige-process-controller',
    dataExtraction: 'dige-data-extraction',
    issueTrackingSystem: 'gen-dige-issue-tracking-system'
  },
};

export const ENV_PRODUCTION: IEnvironmentConfig = {
  BASE_URL: 'https://digitaleye-api.mosaic.siemens-energy.cloud/v1/',
  ...ENV_COMMON,
  MSAL_CONFIG: {
    clientId: 'ce381272-89e6-42b1-a3e4-4d396f6dd808',
    authorityId:
      'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/',
  },
};

export const ENV_DEVELOPMENT: IEnvironmentConfig = {
  BASE_URL: 'https://digitaleye-api.mosaic-dev.siemens-energy.cloud/v1/',
  ...ENV_COMMON,
  MSAL_CONFIG: {
    clientId: '3d03f8aa-978c-4254-9f1f-2ceb4d09855f',
    authorityId:
      'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/',
  },
};

export const ENV_LOCAL: IEnvironmentConfig = {
  BASE_URL: 'http://127.0.0.1/',
  ...ENV_COMMON,
  MSAL_CONFIG: {
    clientId: '3d03f8aa-978c-4254-9f1f-2ceb4d09855f',
    authorityId:
      'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477/',
  },
  SERVICE_ENDPOINTS: {
    fileInventory: ':8081',
    processController: ':8082',
    dataExtraction: ':8084',
    // issueTrackingSystem: 'gen-dige-issue-tracking-system'
    issueTrackingSystem: '8092'
  },
};
